<template>
  <div>
    <b-sidebar
      id="FilterActivityLog"
      v-model="isShowSidebar"
      title="FILTER"
      aria-labelledby="sidebar-no-header-title"
      no-header
      backdrop
      shadow
      right
      ref="FilterActivityLog"
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Filter </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="clearFilter">
              x <span class="text-under">Clear Filter</span>
            </button>
          </b-col>
        </b-row>
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group>
          <b-form-input
            class="search-bar"
            placeholder="Search Name-Surname, IP Address"
            v-model="filter.search"
          >
          </b-form-input>
        </b-input-group>
      </div>
      <div class="px-3">
        <div class="mt-3">
          <div class="font-weight-bold mb-2">Valid From</div>
          <div :class="['input-container']">
            <datetime
              type="date"
              class="date-picker"
              placeholder="Please Select Date"
              format="dd/MM/yyyy"
              value-zone="local"
              ref="transferDateStart"
              v-model="filter.start_date"
            >
            </datetime>
            <div
              :class="'icon-primary text-right'"
              @click="$refs.transferDateStart.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'cursor-default color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="font-weight-bold mb-2">Valid To</div>
          <div class="input-container mt-2">
            <datetime
              type="date"
              class="date-picker"
              placeholder="Please Select Date"
              format="dd/MM/yyyy"
              value-zone="local"
              ref="transferDateEnd"
              v-model="filter.end_date"
            >
            </datetime>
            <div
              :class="'icon-primary text-right'"
              @click="$refs.transferDateEnd.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'cursor-default color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <b-form-group>
            <label class="label-title">Activity type</label><br />
            <b-form-checkbox
              v-model="allSelectedStatus"
              @change="toggleAllStatus"
            >
              All
            </b-form-checkbox>
            <b-form-checkbox-group
              id="status"
              v-model="selectedStatus"
              :options="activityList"
              value-field="id"
              text-field="name"
              name="status"
              class="ml-4"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click.prevent="clearFilter"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitAction"
              block
              variant="light"
              class="text-body rounded-pill btn-main"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    activityList: {
      required: true,
      type: Array,
    },
  },
  watch: {
    selectedStatus(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelectedStatus = false;
      } else if (newValue.length === this.activityList.length) {
        this.allSelectedStatus = true;
      } else {
        this.allSelectedStatus = false;
      }
    },
  },
  data() {
    return {
      validFrom: "",
      validTo: "",
      isShowSidebar: false,
      selectedStatus: [],
      allSelectedStatus: false,
    };
  },
  methods: {
    toggleAllStatus(checked) {
      if (checked) {
        for (const activity in this.activityList) {
          if (
            this.selectedStatus.includes(this.activityList[activity].id) ===
            false
          )
            this.selectedStatus.push(this.activityList[activity].id);
        }
      } else {
        this.selectedStatus = [];
      }
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    searchActivityLog(value) {
      this.$emit("search", value);
    },
    submitAction() {
      if (this.allSelectedStatus || this.selectedStatus) {
        this.filter.activity_type = this.selectedStatus;
      } else {
        this.filter.activity_type = [];
      }
      this.filter.page = 1;
      this.filter.start_date = this.filter.start_date
        ? this.$moment(this.filter.start_date).format("YYYY-MM-DD")
        : null;
      this.filter.end_date = this.filter.end_date
        ? this.$moment(this.filter.end_date).format("YYYY-MM-DD")
        : null;
      this.$emit("submitFilter", this.filter);
      this.hide();
    },
    clearFilter() {
      this.selectedStatus = [];
      this.allSelectedStatus = true;
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.filter.search = "";
      this.filter.activity_type = [];
      this.filter.take = 10;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.btn-close {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}

label {
  margin-bottom: 0;
  font-weight: 600;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 15px;
}

::v-deep .date-picker .vdatetime-input {
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  margin-bottom: 0;
}
::v-deep .date-picker .vdatetime-input {
  border: none !important;
}
</style>
